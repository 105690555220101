import React from 'react';
import { css } from '@emotion/react';
import SanityImage from 'gatsby-plugin-sanity-image';
import Link from './link';
import Pill from './pill';

export default function Card({
  className,
  small,
  url,
  image,
  tag,
  tagColor,
  tagTwo,
  tagTwoColor,
  title,
  oppositeFrame,
}) {
  function stringToColor(str) {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).substr(-2);
    }

    return color;
  }

  return (
    <Link
      className={className}
      css={css`
        display: block;
        position: relative;
        line-height: 0;
        text-decoration: none;
        padding-bottom: 40px;

        &:hover img {
          filter: invert(1);
        }
      `}
      to={url}
      oppositeFrame={oppositeFrame}
    >
      {image?.asset && <SanityImage
        css={css`
          margin-bottom: 20px;
        `}
        {...image}
        width={1360}
        height={800}
      />}
      {!image && <div
        css={css`
          background-color: ${stringToColor(url)};
          aspect-ratio: 1360 / 800;
          margin-bottom: 20px;
        `}
        {...image}
      />}
      {tag && <Pill
        css={css`
          margin: 0 15px 15px 0;
        `}
        color={tagColor}
      >{tag}</Pill>}
      {tagTwo && <Pill
        css={css`
          margin-bottom: 15px;
        `}
        color={tagTwoColor}
      >{tagTwo}</Pill>}
      <h2
        className={(small) ? 'type--west-space type--35' : 'type--50'}
      >{title}</h2>
    </Link>
  );
}
