import React from 'react';
import { graphql } from 'gatsby'
import { css } from '@emotion/react';
import { setName } from '../extra/util';
import SEO from '../components/seo';
import Card from '../components/card';

export default function WhatsOn({ data }) {
  const lf = new Intl.ListFormat('en-AU');
  const df = new Intl.DateTimeFormat('en-AU', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  setName(`What's On`);

  function inRange(startDate, endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate).setHours(23, 59, 59, 999);

    return startDate < new Date() && endDate >= new Date();
  }

  function getDateRange(startDate, endDate) {
    const startParts = df.formatToParts(new Date(startDate));
    const endParts = df.formatToParts(new Date(endDate));

    return `${type('day', startParts)} ${type('month', startParts)} → ${type('day', endParts)} ${type('month', endParts)} ${type('year', endParts)}`;

    function type(type, array) {
      return array.find((item) => item.type === type).value;
    }
  }

  return (
    <>
      <div
        className="type--50"
        css={css`
          background-color: var(--white);
          padding: var(--margin);

          > a {
            padding-top: 45px;
            border-top: var(--borderWidth) solid var(--black);

            &:first-of-type {
              padding-top: 0;
              border-top: none;
            }
          }
        `}
      >
        {data.programs.nodes.length > 0 && data.programs.nodes
          .filter(item => new Date(new Date(item.endDate).setHours(23, 59, 59, 999)) >= new Date())
          .map((item, i) => <Card
            key={i}
            url={`/program/${item.slug.current}`}
            image={item.featuredImage}
            tag={(inRange(item.startDate, item.endDate)) ? 'NOW' : 'NEXT'}
            tagColor={(inRange(item.startDate, item.endDate)) ? '#000000' : '#FFFFFF'}
            tagTwo={item.location}
            tagTwoColor='#FFFFFF'
            title={<>
              &ldquo;{item.title}&rdquo;<br />
              {item.people.length > 0 && lf.format(item.people.map((person) => person.name))}<br />
              {getDateRange(item.startDate, item.endDate)}
            </>}
            oppositeFrame
          />)
        }
        {(data.programs.nodes.length > 0 && data.programs.nodes.filter((item) => new Date(item.endDate) > new Date()).length === 0) && <>No upcoming programs.</>}
      </div>
    </>
  );
}

export function Head({ data }) {
  return <SEO title={`West Space – What's On`} />
}

export const query = graphql`
  query {
    programs: allSanityProgram(sort: {fields: startDate, order: ASC}) {
      nodes {
        title
        slug {
          current
        }
        startDate
        endDate
        featuredImage {
          ...Image
        }
        location
        people {
          name
        }
      }
    }
  }
`;
